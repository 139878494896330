.vg-corona
	padding: 0px 30px
	padding-bottom: 60px

	.header
		font-family: Raleway
		height: 40vh
		position: relative
		background-size: cover
		background-position: center
		display: flex
		flex-direction: column
		justify-content: flex-end
		padding: 30px
		margin: 0px -30px
		box-shadow: 0px 3px 30px rgba(0,0,0,0.5)
		margin-bottom: 40px

		h1
			text-transform: uppercase
			font-family: "Raleway"
			font-weight: 900
			color: #fff
			font-size: 35px
			text-shadow: 2px 2px 10px rgba(0,0,0,.5)
			line-height: 32px
			width: 70%

		h2
			color: hsla(0,0%,100%,.8)
			text-align: left
			font-weight: 700
			font-size: 17px
			margin: 10px 0 0

		.content
			z-index: 2
			position: relative
		.overlay
			background: rgba(0,0,0,0.25)
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			z-index: 1

	.pool-selection
		font-family: "Jost"
		.slider
			display: flex
			margin: 20px -30px
			padding: 10px 0px 10px 30px
			overflow-x: auto
			.pool
				width: 150px
				flex-basis: 150px
				flex-shrink: 0
				margin: 0px 10px
				position: relative
				cursor: pointer
				display: block

				&:last-of-type
					padding-right: 30px

				&.selected
					.name
						color: var(--primary)

				&:first-of-type
					margin-left: 0px
				.img
					width: 100%
					height: 200px
					border-radius: 5px
					box-shadow: var(--boxShadow)
					background-position: center
					background-size: cover
					position: relative
					transition: 0.15s all ease-in-out
					&:hover
						opacity: 0.7

				.name
					font-weight: bold
					font-size: 0.9rem
					margin-top: 15px
					line-height: 0.9rem
				.location
					color: var(--middleGray)
					font-size: 0.8rem
				.im-info
					position: absolute
					top: 5px
					left: 5px
					color: white
					padding: 10px
					z-index: 1
					text-shadow: 1px 1px 3px black

	.slot-selection
		font-family: "Jost"

		.swipeable-container
			margin: 0px -30px

			.day
				padding: 0px 30px

				.date-overview
					margin-top: 30px
					background: var(--white)
					display: flex
					justify-content: space-between
					padding: 10px 15px
					align-items: center
					border-radius: 10px
					box-shadow: var(--boxShadow)

					i
						font-size: 15px
						padding: 5px
						cursor: pointer
						transition: 0.15s all ease-in-out
						&:hover
							opacity: 0.7

					.date
						font-weight: bold

				.slots
					margin-top: 20px
					padding-bottom: 20px

					.slot
						background: var(--white)
						box-shadow: var(--boxShadow)
						padding: 10px 15px
						border-radius: 10px
						margin: 7.5px 0px
						display: flex
						align-items: center
						height: 45px
						cursor: pointer
						transition: 0.15s all ease-in-out
						&:hover
							opacity: 0.7

						.checkmark
							display: flex
							align-items: center
							svg
								width: 25px
								.circle
									stroke: var(--middleGray)
						.slot-data
							margin-left: 20px
							display: flex
							flex-grow: 1
							justify-content: space-between
							align-items: center
							i
								margin-top: 10px
								color: var(--primary)

							p:nth-child(1)
								line-height: 1rem
							p:nth-child(2)
								margin-top: 5px
								font-size: 0.8rem
								line-height: 0.8rem
								color: var(--middleGray)

						&.selected
							background: var(--primary)
							color: white
							i
								margin-top: 10px
								color: var(--white)
							.checkmark
								.circle
									fill: var(--white)
									stroke: var(--white)
								.check
									stroke: var(--primary)
							.slot-data
								p:nth-child(1)
									color: white
								p:nth-child(2)
									font-size: 0.8rem
									color: var(--lightGray)

						&.full
							opacity: 0.3
							pointer-events: none

		.day-indicator
			width: 100%
			display: flex
			justify-content: center
			padding: 10px 0px

			span
				display: block
				width: 18px
				height: 18px
				margin: 5px
				background: var(--white)
				border-radius: 10px
				box-shadow: var(--lightBoxShadow)
				cursor: pointer
				transition: 0.15s all ease-in-out
				&:hover
					opacity: 0.7

				&.active
					background: var(--primary)

	.data-entry
		font-family: "Jost"

		.household
			background: var(--white)
			border-radius: 10px
			box-shadow: var(--boxShadow)
			margin-top: 30px
			padding: 30px 20px
			position: relative

			.delete-household
				position: absolute
				right: 10px
				top: 25px
				color: var(--primary)
				padding: 10px
			h2
				text-transform: uppercase
				font-size: 1.2rem
				color: var(--primary)

			.member-input
				position: relative

				i
					position: absolute
					right: 10px
					top: 50%
					transform: translateY(-50%)
					color: var(--primary)
					font-size: 23px
					line-height: 23px
					margin-top: 2px
					cursor: pointer
					transition: 0.15s all ease-in-out
					&:hover
						transform: translateY(-50%) rotate(90deg)

				input
					padding-right: 30px
				&.new-member
					background: var(--lightGray)
					padding: 2.5px 10px
					border-radius: 10px
					width: calc(100% - 20px)
					margin: 10px 0px
					max-width: 100%
					cursor: pointer

					&:hover i
						transform: translateY(-50%) rotate(90deg)

					&.disabled
						pointer-events: none
						opacity: 0.5
					i
						transition: 0.15s all ease-in-out
					p
						font-family: "Jost"
						color: var(--darkGray)
						font-size: 1rem
						padding: 5px 2.5px
						font-weight: 700

	h1
		font-family: "Jost"
		font-weight: bold
		font-size: 1.3rem
		color: var(--secondary)
		margin-top: 50px

	@media only screen and (min-width: 720px)
		& > div:not(.header)
			max-width: 900px
			width: 100%
			margin-left: auto
			margin-right: auto

			&.info,&.warning
				width: calc( 100% - 40px )
		.header
			margin: 30px 0px
			border-radius: 30px

			.overlay
				border-radius: 30px
			.content
				max-width: 900px
				width: 100%
				margin-left: auto
				margin-right: auto

		.submit-area .onedash-form > div
			display: flex
			margin-top: 30px
			& > div:last-of-type
				margin-left: 30px !important
