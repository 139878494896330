body {
	width: 100vw;
	min-height: 100vh;
	padding: 0px;
	margin: 0px;
	position: relative;
	font-size: 16px;
	font-weight: 500;
	overflow-x: hidden;
	background: var(--background);
	color: var(--text);
	font-family: Jost;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

	* {
		color: var(--text);
		transition: 0.3s background ease-in-out, 0.3s color ease-in-out, 0.3s border-color ease-in-out;

		overflow-wrap: break-word;
		hyphens: auto;
	}
	button {
		background: none;
		border: none;
		border-radius: 0px;
		cursor: pointer;
		display: flex;
		&:focus {
			outline: none;
		}
	}
	h1,
	h2,
	h3,
	h4,
	p,
	ul,
	li {
		margin: 0px;
		padding: 0px;
	}
	li {
		list-style: none;
		&::before {
			content: "\00BB";
			font-weight: bold;
			color: var(--secondary);

			margin-right: 5px;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: none;
		-webkit-box-shadow: 0 0 0px 1000px var(--lightGray) inset;
		box-shadow: 0 0 0px 1000px var(--lightGray) inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	a {
		text-decoration: none;
	}
}
*::-webkit-scrollbar {
	display: none;
}

/**
*			Flexbox default styles
*/
.center-center {
	width: 100%;
	min-height: calc(100vh - 200px);
	display: flex;
	align-items: center;
	justify-content: center;
}

/**
* 			TEXT DEFAULT STYLES
*/
.text-center {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.underline {
	text-decoration: underline;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
#root .onedash-popover-wrapper {
	* {
		font-family: "Jost";
	}
	.popover {
		border-radius: 10px 10px 0px 0px;
		.content {
			margin-top: 0px;
			margin-bottom: 15px;
			.popover-title {
				font-family: "Jost";
				font-weight: bold;
				border: none;
				color: var(--primary);
				text-align: center;
			}
		}
		.popover-close {
			display: none;
		}
	}
}
#root .onedash-form-component {
	&.onedash-input,
	&.onedash-select {
		display: flex;
		align-items: center;
		background: var(--lightGray);
		padding: 2.5px 10px;
		border-radius: 10px;
		width: calc(100% - 20px);
		margin: 10px 0px;
		max-width: 100%;

		&.input-invalid {
			.onedash-label {
				width: 200px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.8);
			}
			background: var(--primary);

			input::placeholder {
				color: white;
			}
		}
		.required {
			display: none;
		}

		.onedash-label {
			font-size: 1rem;
			width: 200px;
			font-weight: bold;
			color: var(--darkGray);
			font-family: "Jost";
		}
		input,
		select {
			font-size: 1rem;
			background: none;
			border: none;
			margin-top: 0px;
			font-weight: bold;
			font-family: "Jost";
		}
	}

	&.onedash-boolean {
		margin: 10px 0px;
		max-width: 100%;
		.checker {
			margin-top: 0px;
		}
		.check-bg {
			background: none;
			&::after {
				background: none;
			}
		}
	}
}
#root .onedash-card {
	background: var(--white);
	border-radius: 10px;
	box-shadow: var(--boxShadow);

	padding: 30px 20px;
	position: relative;
	width: auto;
	max-width: 100%;
	margin: 0px;
	margin-top: 30px;

	h2 {
		text-transform: uppercase;
		font-size: 1.2rem;
		color: var(--primary);
	}
}
#root .splitted-switch {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 100%;
	.checker {
		margin-right: 0px;
	}
}
#root .btn {
	background: var(--primary);
	border: 2px solid var(--primary);
	color: #fff;
	text-align: center;
	font-family: "Jost";
	width: calc(100% - 25px);
	margin: 30px 0;
	border-radius: 10px;
	font-weight: 700;
	display: block;
	font-size: 1rem;
	padding: 10px 12.5px;
	transition: all 0.15s ease-in-out;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-transform: uppercase;
	font-size: 1.2rem;

	&.disabled {
		border-color: var(--tools-light-gray) !important;
	}
}
#root .privacy-text {
	font-family: "Jost";
	font-size: 0.8rem;
	line-height: 0.8rem;
	color: var(--darkGray);

	* {
		font-family: "Jost";
	}
	a {
		color: var(--secondary);
		text-decoration: none;
	}
}
#root .onedash-button {
	text-align: center;
	font-family: "Jost";
	box-shadow: var(--boxShadow);
	width: 100%;
	margin: 30px 0px;
	border-radius: 10px;
	font-weight: bold;
	display: block;

	&.disabled {
		border-color: var(--tools-light-gray) !important;
	}
}
#root .style-twenty.onedash-dialog {
	.dialog {
		width: calc(100% - 60px);
		padding-bottom: 0px;
		margin-top: -60px;

		padding-right: 10px;
		& > div {
			margin: 0px;
		}
		.dialog-title {
			font-family: "Jost";
			font-weight: bold;
			font-size: 1.6rem;
			color: var(--secondary);
			border: none;
			line-height: 100%;
			padding-bottom: 0px;
		}

		.dialog-content {
			max-height: calc(90vh - 150px);
			font-size: 1rem;
			line-height: 150%;
			font-weight: normal;

			a {
				color: var(--secondary);
				text-decoration: none;
				word-break: break-all;
			}

			h3 {
				color: var(--primary);
				text-transform: uppercase;
				margin: 20px 0px 10px 0px;
				font-size: 1.3rem;
			}
			h4 {
				color: var(--darkdarkGray);
				font-weight: bold;
				margin: 10px 0px -5px 5px;
				font-size: 1.1rem;
			}
			p,
			ul {
				margin: 5px 0px 7.5px 5px;

				li::before {
					content: "\00BB";
					font-weight: bold;
					color: var(--secondary);

					margin-right: 5px;
				}
			}
			ul {
				margin: 5px 0px 7.5px 10px;
			}
		}
	}
}

.error {
	background: var(--primaryLight);
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: 30px;
	line-height: 1.2rem;
	transition: 0.3s all linear;
	overflow: hidden;
	max-height: 0px;

	&.visible {
		max-height: 150px;
	}
	i {
		color: var(--primary);
		margin-left: 20px;
	}
	.text {
		margin: 20px;
		color: var(--primary);
	}
}
.info {
	background: var(--secondaryLight);
	padding: 10px 20px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: 30px;
	line-height: 1.2rem;
	i {
		color: var(--secondary);
	}
	.text,
	.text p,
	.text span {
		color: var(--secondary);
	}
	.text {
		margin-left: 20px;
	}
}
.warning {
	background: var(--warningLight);
	padding: 10px 20px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: 10px;
	line-height: 1.2rem;
	i {
		color: var(--warning);
	}
	.text,
	.text p,
	.text span {
		color: var(--warning);
	}
	.text {
		margin-left: 20px;
	}
}
#root .onedash-popover-wrapper.submit-popup {
	h1 {
		text-align: center;
		font-size: 1.3rem;
		line-height: 115%;
		margin-top: -50px;
	}
	.wallet-link {
		margin-left: auto;
		width: 150px;
		display: block;
		margin-right: auto;
		margin-top: 20px;
		img {
			width: 100%;
		}
	}
	p {
		text-align: center;
		margin-top: 20px;
	}
	svg {
		margin-top: -30px;
	}
}
#root .onedash-popover-wrapper {
	font-weight: normal;

	h1 {
		margin-top: 20px;
	}
}
