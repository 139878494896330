.menu-card
    width: 100%
    max-width: 900px
    height: 90vh
    position: relative
    margin-left: auto
    margin-right: auto
    iframe
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
        margin: 0px
        border: none
