#root .home-btn {
	position: absolute;
	padding: 10px;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	right: 15px;
	top: 20px;
	transition: 0.3s all linear;
	@media only screen and (min-width: 720px) {
		right: 55px;
	}
	i {
		font-size: 30px;

		color: white;
		text-shadow: 0 0 20px rgba(0, 0, 0, 1);
	}

	&.open {
		transform: rotate(180deg);
	}
}
#root .home {
	--onedash-white: white;
	--onedash-orange: #e88760;
	--onedash-green: #28ffac;

	background: #3a2850f8;
	width: calc(100vw - 60px);
	padding: 50px 30px 30px 30px;
	overflow: hidden;
	max-height: 0px;
	z-index: 99;
	padding-top: 0px;
	padding-bottom: 0px;
	transition: 0.3s all linear;
	position: fixed;
	top: 0px;

	&.open {
		padding: 100px 30px 30px 30px;
		max-height: calc(100vh - 130px);
		overflow: auto;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	.app-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-auto-rows: 1fr;
		grid-gap: 10px;
		margin-top: 50px;

		&:before {
			content: "";
			width: 0;
			padding-bottom: 100%;
			grid-row: 1 / 1;
			grid-column: 1 / 1;
		}

		.app-tile {
			border-radius: 20px;
			background: rgba(255, 255, 255, 0.4);
			padding: 15px;
			margin: 5px;
			display: flex;
			text-decoration: none;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
			flex: 1 0 auto;
			height: auto;
			flex-basis: 150px;
			align-items: center;
			justify-content: center;

			&:first-child {
				grid-row: 1 / 1;
				grid-column: 1 / 1;
			}

			h2 {
				color: white;
				font-size: 20px;
				text-align: center;
				line-height: 100%;
				margin-top: 10px;
				font-size: 18px;
			}
			.description {
				text-align: center;
				line-height: 100%;
				font-size: 15px;
				margin-top: 10px;
				color: rgba(255, 255, 255, 0.6);
			}
			img {
				margin-left: auto;
				margin-right: auto;
				height: 50px;
				display: block;
			}
		}
	}

	.vg-select {
		.select {
			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				margin-top: 0px;
				margin-left: 0px;
			}
			i {
				color: white;
				font-size: 15px;
				position: absolute;
				margin-left: -20px;
				margin-top: 10px;
				z-index: -1;
			}
		}
		h1 {
			color: white;
			font-size: 28px;
		}
		.onedash-select {
			max-width: 100%;
			width: auto;
			margin: 0px;
			padding: 0px;

			select {
				color: white;
				border: none;
				font-weight: 900;
				font-size: 22px;
				height: auto;
				padding: 5px 10px;
				max-width: 100%;
				width: 100%;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		h3 {
			color: white;
		}
		.onedash-logo {
			span {
				&:nth-child(1) {
					color: var(--onedash-white);
				}
				&:nth-child(2) {
					color: var(--onedash-orange);
				}
				&:nth-child(3) {
					color: var(--onedash-green);
				}
			}
		}
	}
}
